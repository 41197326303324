import "../landingPageStyles/about.scss";
import content from "../assets/images/content.png"
// import { AiOutlinePlus } from "react-icons/ai"


const About = () => {
  return (
    <div className='about'>
        <div className="container">
            <div className="row">
            <div className="heading">
                <h1>We Are Here for You</h1>
                <p>We offer 24hrs 7 days community management on various channels and community, including to moderate, host community AMA and bot implementations.</p>
            </div>
            <div className="grid__wrapper">
                <div className="grid__item grid__item1">
                {/* <div className="box">
                        <AiOutlinePlus fill="#FFF" />
                    </div> */}
                    <div className="img__holder">
                        <img src={ content } className="icons content__icon" alt="Content Management" />
                    </div>
                    <div className="text">
                        <h5>Community Management</h5>
                        <p>We provide quality community management for your blockchain project. Boost your community engagement with us.</p>
                    </div>
                </div>
                <div className="grid__item grid__item2">
                {/* <div className="box">
                        <AiOutlinePlus fill="#FFF" />
                    </div> */}
                    <div className="img__holder">
                        <img src={ content } className="icons content__icon" alt="Content Management" />
                    </div>
                    <div className="text">
                        <h5>Social Media Management</h5>
                        <p>We serve updated contents that fit market trends, adoption and prompt engagement.</p>
                    </div>
                </div>
                <div className="grid__item grid__item3">
                {/* <div className="box">
                        <AiOutlinePlus fill="#FFF" />
                    </div> */}
                    <div className="img__holder">
                        <img src={ content } className="icons content__icon" alt="Content Management" />
                    </div>
                    <div className="text">
                        <h5>Reach New Audience</h5>
                        <p>We bring quality users and customers to your project.</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default About