//Bring in Scroll-to-top component
import ScrollToTop from "./components/ScrollToTop";

//Bring in pages
import Index from "./pages/Index";
import Partners from "./pages/Partners";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <ToastContainer />
    <Router>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={ <Index /> }></Route>
        <Route path="/partners" element={ <Partners /> }></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
