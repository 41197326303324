
import "../landingPageStyles/herosection.scss"
import heroImg from "../assets/images/hero-img.svg";
import patter1 from "../assets/images/pattern1.svg"
import Navbar from "./Navbar"
import { MdClose } from 'react-icons/md';
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";



const Herosection = () => {

    const [formData, setFormData] = useState({
        project_name: "",
        project_website: "",
        email_address: "",
        telegram_username: "",
        service_needed: "",

    })

    //Onchange 
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id] : e.target.value
        }
        ))
    }

    const { project_name, project_website, email_address, telegram_username, service_needed } = formData
    const [showForm, setShowForm] = useState(false)

    //Method to submit form
    const submitForm = async (e) => {
        e.preventDefault()
       //Check to make sure the form is field out
       if(!project_name || !project_website || !email_address || !telegram_username || !service_needed){
           toast.error("Please, completely fill out the form")
       } else {
           try {
            const message = `<p>
            <b>Form Request Details</b> <br />
            ==================================== <br /><br />
            Project Name: ${ project_name } <br /><br />
            Project Website: ${ project_website } <br /><br />
            Email Address: ${ email_address } <br /><br />
            Telegram Username: ${ telegram_username } <br /><br />
            Service(s) Needed: ${ service_needed }  
           </p>`

           const details = {
               subject: "Service request from active4.co website",
               formData,
               message,
               receiver: "kelly@active4.co"
           }
           const response = await axios.post("https://muna-email-sender.herokuapp.com/send-message", details)
           const responseMessage = response.data.message
           toast.success(responseMessage)
           } catch (error) {
               let errorMessage
               if(error.message === "Request failed with status code 500"){
                    errorMessage = "Error sending email"
               } else {
                   errorMessage = "Oops! Something went wrong. Please try again"
               }
               console.log(error.message)
               toast.error(errorMessage)
           }
       
    }
}


  return (
    <div className="herosection">
        <Navbar />
        <div className="blob__section">
            <img src={ patter1 } className="pattern__img" alt="Active4 Blob" />
        </div>
        <div className="container">
            <div className="hero__contents">
                { showForm ? <div className="herosection__form">
                    <MdClose size="1.5rem" fill="#091963" onClick={() => setShowForm(() => !showForm)} style={{ position: "absolute", right: "10px", top: "10px", fontWeight: "bolder" }} />
                    <h3>Service Request Form</h3>
                    <hr />
                <form onSubmit={ submitForm } className="contact_form">
                <div className="form-group">
                    <label htmlFor="project-name">Project Name *</label>
                    <input type="text" className="form-control" placeholder="Project Name" value={ project_name } onChange={ onChange } id="project_name"/>
                </div>
                <div className="form-group">
                    <label htmlFor="project-website">Project Website *</label>
                    <input type="text" className="form-control" placeholder="Project Website" value={ project_website } onChange={ onChange } id="project_website"/>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input type="email" className="form-control" placeholder="Email Address" value={email_address} onChange={ onChange } id="email_address"/>
                </div>
                <div className="form-group">
                    <label htmlFor="telegram-username">Telegram Username *</label>
                    <input type="text" className="form-control" placeholder="Telegram Username" value={telegram_username} onChange={ onChange } id="telegram_username"/>
                </div>
                <div className="form-group">
                    <label htmlFor="service-needed">Service Needed *</label>
                    <input type="text" className="form-control" placeholder="Service Needed" value={service_needed} id="service_needed" onChange={ onChange }/>
                </div><br /><br />
                {/* <a className="hero__form__btn" href= { `mailto:kelly@active4.co?project_name = ${project_name}&project_website = ${project_website}&email_address=${email_address}&telegram_username=${telegram_username}&service_needed=${service_needed}` } >Send Message</a> */}


                <button type="submit" value="submit" className="hero__form__btn">Send Message</button>
                </form> 
                </div> : null }
            <div className="row">
                <div className="col-md-5">
                    <div className="text__section">
                        <h1>Active4 Digital Marketing Agency</h1>
                        <p>We are a group of professional digital specialists, investors and technology enthusiasts from different parts of the world with various technical expertise and cognitive experience in the blockchain industry.</p>
                        <div className="button__holder d-flex">
                       <div onClick={() => setShowForm(() => !showForm)} className="button primary">Get Started</div>
                       {/* <Link to="/" className="button secondary">Join Us</Link> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
                <div className="d-none d-md-block col-md-6">
                    <img src={ heroImg } className="hero__img img-fluid" alt="Marketing Svg" />
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Herosection