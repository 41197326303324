import "../landingPageStyles/media.scss";
import co_workers from "../assets/images/co-workers.svg";

const Media = () => {
  return (
    <div className='media'>
        <div className="container">
            <div className="media__content">
                <div className="intro">
                <h4>Social Media Strategy</h4>
                <h1>Creative Solutions To Improve Blockchain</h1>
                </div>
                <img src={ co_workers } className="img-fluid media__img" alt="Co-Workers" />
            </div>
        </div>
    </div>
  )
}

export default Media