import "../landingPageStyles/services.scss";
import services__img from "../assets/images/hero-img.svg";
import { IoIosSettings } from "react-icons/io"

const Services = () => {
  return (
    <div className='services'>
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <div className="details">
                        <div className="intro">
                            <h1>We are obsessively passionate about delivering quality service.</h1>
                            <p>We offer 24hour 7 days community</p>
                        </div>
                        <div className="description">
                            <div className="icon__holder">
                                <IoIosSettings fill="#FFF" size="1.4rem" />
                            </div>
                            <div className="description__details">
                                <h4>We Are Here to Help You Out</h4>
                                <p>Focus on building your ideas and solutions, we will handle the rest.</p>
                            </div>
                        </div>
                        <br />
                        <div className="description description2">
                            <div className="icon__holder">
                                <IoIosSettings fill="#FFF" size="1.4rem" />
                            </div>
                            <div className="description__details">
                                <h4>Build and Ship Faster</h4>
                                <p>Ship faster while we take up your maketing burdens and work.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <img src={ services__img } className="service__img img-fluid" alt="Professional Services" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services