import "../landingPageStyles/testimonial.scss";
import icon from "../assets/images/team1.png";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import testifier1 from "../assets/images/testifier1.jpg"
import testifier2 from "../assets/images/testifier2.jpeg"
import testifier3 from "../assets/images/testifier3.jpeg"
import testifier4 from "../assets/images/testifier4.png"

const Testimonials = () => {
  return (
    <div className='testimonials'>
        <div className="test__img__holder pt-5 d-none d-md-block">
            <div className="client client1">
                <img src={ icon } className="client__img" alt="" />
            </div>
            {/* <div class="client client2">
                <img src={ icon } className="client__img" alt="" />
            </div> */}
            <div className="client client3">
                <img src={ icon } className="client__img" alt="" />
            </div>
            <div className="client client4">
                <img src={ icon } className="client__img" alt="" />
        </div>
        <div className="client client5">
                <img src={ icon } className="client__img" alt="" />
        </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <h1>Testimonials</h1>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 testimony">
                        {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid non ipsa, veritatis impedit quo distinctio maxime maiores voluptatibus atque ratione, excepturi culpa voluptate! Consequuntur</p>
                        <strong>Henry Chuckwuka</strong> */}
                        <OwlCarousel className='owl-theme' loop rewind margin={0} items={1} autoplay>
                            <div className='item'>
                                <img src={ testifier1 } className="testifier__img" alt="" />
                                <p>"The CMs have been outstanding, with positive performance so far"</p>
                                <hr />
                                <h5>Anita Moore, CEO Blindboxes</h5>
                            </div>
                            <div className='item'>
                                <img src={ testifier2 } className="testifier__img" alt="" />
                                <p>"The admin team has been great since we swapped in Jnatsu. Response time has been very good so far, and they have great work ethics as well"</p>
                                <hr />
                                <h5>ERIC G, CEO Flourishing Capital AI</h5>
                            </div>
                            <div className='item'>
                                <img src={ testifier3 } className="testifier__img" alt="" />
                                <p>"Overall, the response times are good, problems are handled carefully, and I like that they always ask questions regarding technical aspects of our project. Great work from the CM team!"</p>
                                <hr />
                                <h5>Aeryn Quamby, COO Xion Finance</h5>
                            </div>
                            <div className='item'>
                                <img src={ testifier4 } className="testifier__img" alt="" />
                                <p>"The admins are very much on top of things. They have quick responses and good manners, and they learn really fast to understand the project. It has been a pleasure to work with the team"</p>
                                <hr />
                                <h5>ZKswap official, CMO Zkswap</h5>
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimonials