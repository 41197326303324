import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MdMenu, MdClose } from 'react-icons/md';
import logo from "../assets/images/logo.png"
import "../landingPageStyles/Navbar.scss";
import { useState } from 'react';

//Handle page active link

const Navbar = () => {

const [showNavigation, setShowNavigation] = useState(false);

const location = useLocation()

const closeMenu = () => {
    setShowNavigation(false)
    
}

const matchRoutePath = (route) => {
    if(route === location.pathname){
        return true
    }
}

  return (
    <div className="navbar__wrapper">
        <div className="container">
            <div className="navbar__content">
                <div className="logo_section">
                    <Link to="/">
                        <img src={ logo } alt="logo" className='logo' />
                        </Link>
                </div>
                    <ul className={ showNavigation ? "showNav" : "hideNav" }>
                        <li onClick={ closeMenu }><Link to="/" className={ matchRoutePath("/") ? "underline" : "ordinary" } >Home</Link></li>
                        <li onClick={ closeMenu }><Link to="/" className={ matchRoutePath("/about") ? "underline" : "ordinary" }>About</Link></li>
                        <li onClick={ closeMenu }><Link to="/partners" className={ matchRoutePath("/partners") ? "underline" : "ordinary" }>Our Partners</Link></li>
                        {/* <li onClick={ closeMenu }><Link to="/" className='register login'>FAQ</Link></li> */}
                        <li onClick={ closeMenu }><a href="https://twitter.com/Active4co" className='register'>Community</a></li>
                    </ul>
                <button style={{ border: "none" }} onClick={() => setShowNavigation(() => !showNavigation) } className='button'>
                    { showNavigation ? <MdClose style={{ fontSize: "1.5rem", background: "#fff", borderRadius: "10px !important", color: "#3D53BA"}} /> :  <MdMenu style={{ fontSize: "1.5rem", background: "#fff", borderRadius: "10px !important", color: "#3D53BA"}} />}
                </button>
            </div>
        </div>
    </div>
  )
}

export default Navbar