import "../landingPageStyles/contact.scss";
import { useState } from "react";
import { toast } from "react-toastify";

const Contact = () => {

    const [formData, setFormData] = useState({
        email: "",
        comment: ""
    })

    const { email, comment } = formData

    const onChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.id] : [e.target.value]
        }))
    }

    //HandleFormSubmit
    const handleFormSubmit = (e) => {
        e.preventDefault()
        if(!email || !comment){
            toast.error("Oops! Please, all fields are required")
        } else {
            toast.success("Submitted successfully. Kindly await our feedback!")
        }
    }

  return (
    <div className='contact'>
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <div className="contact__text">
                        <h2>We Are On A Mission To Improve Blockchain Projects</h2>
                        <p>  Together we have accomplished some of the best results and growth for several decentralized startups while building a long-lasting relationship</p>
                    </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="form__wrapper">
                        <form onSubmit={ handleFormSubmit }>
                            <div className="form-group">
                                <input 
                                    type="email" 
                                    className="form-control"
                                    placeholder="Email"
                                    value={ email }
                                    id="email"
                                    onChange={ onChange }
                                    />
                            </div><br />
                            <div className="form-group">
                                <textarea 
                                    placeholder="Your comment..."
                                    className="form-control"
                                    name="comment"
                                    id="comment" 
                                    cols="10"
                                    onChange={ onChange } 
                                    value={ comment }
                                    rows="5">
                                    </textarea>
                            </div><br />
                            <button type="submit" className="form__btn">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact