import "./pagesStyles/partnerspage.scss";
import { Link } from "react-router-dom";
import zkswap from "../components/assets/images/zkswap.png"
import ioz from "../components/assets/images/ioz.jpeg"
import enecuum from "../components/assets/images/enecuum.png"
import blind from "../components/assets/images/blind.jpeg"
import xion from "../components/assets/images/xion.png"
import showcase from "../components/assets/images/showcase.png"
import volatality from "../components/assets/images/volatility.png"
import scotty from "../components/assets/images/scotty.png"
import safe_launch from "../components/assets/images/safe-launch.png"
import pol from "../components/assets/images/polkafantasy.png"
import moon from "../components/assets/images/moon.png"
import mercor from "../components/assets/images/mercor.png"
import infinity from "../components/assets/images/infinity.png"
import infinite_skies from "../components/assets/images/infinity-skies.png"
import imperex from "../components/assets/images/impermax.png"
import flourish from "../components/assets/images/flourish-capital.png"
import elfin from "../components/assets/images/elfin.png"
import dopex from "../components/assets/images/dopex.png"
import debase from "../components/assets/images/debase.png"
import crowny from "../components/assets/images/crowny.png"
import bslaunch from "../components/assets/images/bsclaunch.png"
import bcmhunt from "../components/assets/images/bcmhunt.png"
import argo from "../components/assets/images/argo.png"


import hero_img from "../components/assets/images/hero-img.svg"
import Footer from "../components/landingPageComponents/Footer";

import Navbar from "../components/landingPageComponents/Navbar";

const Partners = () => {
  return (
    <div>
        <Navbar />
        <div className="partners__herosection">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="partners__text">
                            <h1>See Our Partners</h1>
                            <p>A team with collectively over 5 years of experience working in the decentralized and blockchain technology industry. Together we have accomplished some of the best results and growth for several decentralized startups while building a long-lasting relationship, bestowing upon us a good reputation.</p>
                            <Link to="/" className="partners__btn">Become A Partner</Link>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                        <img src={ hero_img } className="hero__img img-fluid" alt="Partners" />
                    </div>
                </div>
            </div>
        </div>
        <div className="partners__list">
        <div className="container">
        <div className="partners__grid__section">
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ zkswap } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ ioz } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ enecuum } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ blind } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ xion } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ showcase } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ volatality } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ scotty } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ safe_launch } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ pol } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ moon } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ mercor } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ infinity } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ infinite_skies } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ imperex } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ flourish } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ elfin } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ dopex } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ debase } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ crowny } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ bslaunch } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ bcmhunt } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
            <div className="partners__grid__item">
                <Link to="">
                    <img src={ argo } className="img-fluid partner__img" alt="Our Partners" />
                </Link>
            </div>
        </div>
        </div>
    </div>
    <Footer />
    </div>
  )
}

export default Partners