import "../landingPageStyles/partners.scss";
import zkswap from "../assets/images/zkswap.png"
import ioz from "../assets/images/ioz.jpeg"
import enecuum from "../assets/images/enecuum.png"
import blind from "../assets/images/blind.jpeg"
import { Link } from "react-router-dom";

const Partners = () => {
  return (
    <div className='partners'>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-center">
              <h1>Partners</h1>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="partners__grid">
            <div className="item">
              <img src={ enecuum } className="img-fluid partners__img" alt="Our Patners" />
            </div>
            <div className="item">
              <img src={ zkswap } className="img-fluid partners__img" alt="Our Patners" />
            </div>
            <div className="item">
              <img src={ ioz } className="img-fluid partners__img" alt="Our Patners" />
            </div>
            <div className="item">
              <img src={ blind } className="img-fluid partners__img" alt="Our Patners" />
            </div>
          </div>
          <div className="button__section text-center">
          <Link to="/partners" className="partners__button">See More Partners</Link>
          </div>
        </div>
    </div>
  )
}

export default Partners