import "../landingPageStyles/floatingbar.scss";
import { FaLayerGroup } from "react-icons/fa"
import { GiLoveHowl } from "react-icons/gi"
const FloatingBar = () => {
  return (
    <div className="floatingbar">
          <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 content">
              <div className="item item1">
                <span className="icon__holder">
                  <FaLayerGroup />
                </span>
                <div className="item__text">
                  <h5>Grow</h5>
                  <p>Start your growth journey with Active4 </p>
                </div>
              </div>
              <div className="item item2">
                <span className="icon__holder">
                  <GiLoveHowl />
                </span>
                <div className="item__text">
                  <h5>Stay Active</h5>
                  <p>Boost community engagement</p>
                </div>
              </div>
              <div className="item item3">
                <span className="icon__holder">
                  <GiLoveHowl />
                </span>
                <div className="item__text item__text3">
                  <h5>Expand</h5>
                  <p>Reach new markets and customers</p>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
  )
}

export default FloatingBar