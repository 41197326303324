import "../landingPageStyles/footer.scss";
import { MdOutlineMarkEmailUnread } from "react-icons/md"

const Footer = () => {
  return (
    <footer>
       <div>
       <span><a href="mailto:contact@active4.co"><MdOutlineMarkEmailUnread size="1.5rem" /> SEND US AN EMAIL</a></span><br />
        <p>@ 2022 Active4.com | All Rights Reserved.</p>
       </div>
    </footer>
  )
}

export default Footer