//Bring in components
import Herosection from "../components/landingPageComponents/Herosection"
import FloatingBar from "../components/landingPageComponents/FloatingBar"
import About from "../components/landingPageComponents/About"
import Services from "../components/landingPageComponents/Services"
import Team from "../components/landingPageComponents/Team"
import Media from "../components/landingPageComponents/Media"
import Contact from "../components/landingPageComponents/Contact"
import Mission from "../components/landingPageComponents/Mission"
import Partners from "../components/landingPageComponents/Partners"
import Testimonials from "../components/landingPageComponents/Testimonials"
import Footer from "../components/landingPageComponents/Footer"

const Index = () => {
  return (
    <div>
        <Herosection />
        <FloatingBar />
        <About />
        <Services />
        <Team />
        <Media />
        <Contact />
        <Mission />
        <Partners />
        <Testimonials />
        <Footer />
    </div>
  )
}

export default Index