import "../landingPageStyles/mission.scss";
import { MdOutlineContactSupport } from "react-icons/md"
import co_workers from "../assets/images/co-workers.svg";

const Mission = () => {
  return (
    <div className='mission'>
        <div className="container">
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 heading">
                    <h1>We are on a mission to improve Blockchain Projects</h1>
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <img src={ co_workers } className="img-fluid mission__img" alt="Mission Statement" />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                    <div className="mission__content">
                        <div className="icon__holder">
                            <MdOutlineContactSupport fill="#FFF" size="1.5rem" />
                        </div>
                        <div className="mission__text">
                            <h3>We Are Here To Help You Out</h3>
                            <p>Active4.co is a marketing platform and that gives DAOs, DEXs, Dapps and blockchain entrepreneurs a means  to reach new customers.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mission