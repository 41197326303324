import "../landingPageStyles/team.scss";
import henry from "../assets/images/avatar.png"
import {  BsLinkedin, BsTwitter } from "react-icons/bs"

const Team = () => {
  return (
    <div className="team">
        <div className="container">
            <div className="content">
                <h1>Meet Our Expert Founder</h1>
                <div className="team__grid">
                    <div className="team__grid__item">
                        <div className="wrapper">
                            <img src={ henry } className="team__img" alt="Henry Chukwuka" />
                                <div className="team__details">
                                <h5 className="name">Kelly Ifaluyi</h5>
                                <h6 className="position">C.E.O and Team Lead, Digital Marketing Specialist, Investor.</h6>
                                <div className="socials d-flex">
                                <a href="https://www.linkedin.com/in/kelly-ifaluyi-5478b2169/" className="linkedin"><BsLinkedin /></a>
                                <a href="https://twitter.com/IEKbitcoin" className="twitter"><BsTwitter /></a>
                                {/* <Link to="" className="facebook"><BsFacebook /></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Team